import React, { useState, useEffect } from "react";
import { Area, AreaConfig } from "@ant-design/plots";
import { Typography } from "antd";

const { Title } = Typography;

interface TrafficChangeData {
  type: string;
  timestamp: string;
  value: number;
}

function parseData(data: {}): TrafficChangeData[] {
  const result: TrafficChangeData[] = [];
  for (const [type, rowData] of Object.entries(data)) {
    // @ts-ignore
    const timestamps: string[] = rowData["timestamps"];
    // @ts-ignore
    const values: number[] = rowData["values"];
    const length = timestamps.length;
    for (let index = 0; index < length; index += 1) {
      result.push({
        type,
        timestamp: timestamps[index],
        value: values[index],
      });
    }
  }
  return result;
}

const TrafficChangeComponet = () => {
  const [trafficChangeData, setTrafficChangeData] = useState<
    TrafficChangeData[]
  >([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch("https://traffic-kv.szsuzyli.workers.dev/traffic-change")
      .then((response) => response.json())
      .then((json) => {
        setTrafficChangeData(parseData(json["data"]));
      })
      .catch((error) => {
        console.error("fetch data failed", error);
      });
  };

  const config: AreaConfig = {
    data: trafficChangeData,
    meta: {
      timestamp: {
        type: "time",
        mask: "YYYY-MM-dd HH:mm:ss",
      },
    },
    xField: "timestamp",
    yField: "value",
    seriesField: "type",
    slider: {
      start: 0,
      end: 1,
    },
  };

  return (
    <>
      <Title level={2}>Traffic Change</Title>
      <Area {...config} />
    </>
  );
};

export default TrafficChangeComponet;
