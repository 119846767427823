import React, { useEffect, useState } from "react";
import { Card, Divider, List, Statistic } from "antd";
import Meta from "antd/es/card/Meta";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Typography } from "antd";

const { Title, Text, Link } = Typography;

interface PopularDomain {
  rank: number;
  rankChange: number;
  domain: string;
  category: string;
}

const PopularDomainsComponent: React.FC = () => {
  const [popularDomains, setPopularDomains] = useState<PopularDomain[]>([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch("https://traffic-kv.szsuzyli.workers.dev/popular-domains")
      .then((response) => response.json())
      .then((json) => {
        const rankingEntries = json["rankingEntries"] as PopularDomain[];
        setPopularDomains(rankingEntries);
      })
      .catch((error) => {
        console.error("fetch data failed", error);
      });
  };

  return (
    <>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 3,
        }}
        header={<Title level={2}>Popular Domains</Title>}
        dataSource={popularDomains}
        renderItem={(item) => {
          const { rankChange, domain, rank, category } = item;
          let suffix = null;
          const rankChangeFontSizeStyle = {fontSize: 14};
          if (rankChange < 0) {
            const colorStyle = {color: "#cf1322"};
            suffix = (
              <div style={{...colorStyle,}}>
                <ArrowDownOutlined />
                <Text style={{...rankChangeFontSizeStyle, ...colorStyle}}>{rankChange}</Text>
              </div>
            );
          }

          if (rankChange > 0) {
            const colorStyle = {color: "#3f8600"};
            suffix = (
              <div style={{...colorStyle}}>
              <ArrowUpOutlined />
              <Text style={{...rankChangeFontSizeStyle, ...colorStyle}}>+{rankChange}</Text>
            </div>
            );
          }

          return (
            <List.Item>
              <Card>
                <Meta
                  title={
                    <Link style={{fontSize: 22}} href={"http://" + domain} target="_blank">
                      {domain}
                    </Link>
                  }
                  description={category}
                />
                <Divider />
                <Statistic
                  title="Rank"
                  suffix={suffix}
                  value={rank + 1}
                  valueStyle={{ color: "#141414" }}
                />
              </Card>
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default PopularDomainsComponent;
