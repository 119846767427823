import React from "react";
import { Layout, Space, Tabs, theme, Typography } from "antd";
import "./App.css";
import TrafficChange from "./traffic_change/TrafficChange";
import PopularDomains from "./popular_domains/PopularDomains";
import AttackLayer3Component from "./attack_layer3/AttackLayer3";
import Link from "antd/es/typography/Link";

const { Header, Content, Footer } = Layout;
const {Title} = Typography;

const tabKeyValue = [
  {
    label: "Traffic Change",
    key: "trafficChange",
    children: <TrafficChange />,
  },
  {
    label: "Attack Layer 3",
    key: "attackLayer3",
    children: <AttackLayer3Component />,
  },
];

const App: React.FC = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout className="layout">
      <Header 
      style={{display: 'flex', alignItems: 'center'}}>    <Title level={3} style={{color: '#fff'}}>Traffic Dashboard</Title>
</Header>
      <Content style={{ padding: "0 50px" }}>
        <div
          style={{
            minHeight: "500px",
            padding: "0 24px 24px",
            background: colorBgContainer,
          }}
        >
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Tabs
              destroyInactiveTabPane
              defaultActiveKey="trafficChange"
              size="large"
              style={{ marginBottom: 32 }}
              items={tabKeyValue}
            />
            <PopularDomains />
          </Space>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>
        Traffic Data Dashboard Created by{" "}
        <Link href="https://github.com/Shuozeli" target="_blank">
          Shuoze Li
        </Link>
      </Footer>
    </Layout>
  );
};
export default App;
